import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import Text from 'components/ui/Text';

import styles from './Image.module.scss';

const Image = ({
    image,
    caption,
    isTop,
    isMiddle,
    isBottom,
    isPrev,
    isForward,
}) => {
    return (
        <figure
            className={cx(styles.root, {
                [styles.top]: isTop,
                [styles.middle]: isMiddle,
                [styles.bottom]: isBottom,
                [styles.prev]: isPrev,
                [styles.isForward]: isForward,
                [styles.withCaption]: caption,
            })}
        >
            <div className={styles.imageInner}>
                {image?.withFlag && (
                    <div className={styles.flag}>
                        <img
                            className={styles.star}
                            src="/images/campaigns/ncd-23/star.svg"
                            alt="star on flag"
                        />
                        <Text as="p" className={styles.message}>
                            Buy one <br /> Get one
                        </Text>
                        <Text as="p" className={styles.free}>
                            <strong>Free</strong>
                        </Text>
                    </div>
                )}
                {image?.url && image?.width && (
                    <Img
                        className={styles.image}
                        src={image.url}
                        fallbackImageWidth={278 * 2}
                        alt={image.description || ''}
                        width={image.width}
                        height={image.height}
                        customSources={[
                            {
                                breakpoint: 1024,
                                src: image.url,
                                imageWidth: 700 * 2,
                            },
                            {
                                breakpoint: 768,
                                src: image.url,
                                imageWidth: 600 * 2,
                            },
                            {
                                src: image.url,
                                imageWidth: 278 * 2,
                            },
                        ]}
                    />
                )}
            </div>
            {caption && (
                <Text
                    as="figcaption"
                    baseTheme="headingSmall"
                    className={styles.caption}
                >
                    {caption}
                </Text>
            )}
        </figure>
    );
};

Image.propTypes = {
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
        withFlag: PropTypes.bool,
    }),
    caption: PropTypes.string,
    isTop: PropTypes.bool,
    isMiddle: PropTypes.bool,
    isBottom: PropTypes.bool,
    isPrev: PropTypes.bool,
    isForward: PropTypes.bool,
};

export default Image;
