'use client';

import React, { useEffect, useMemo } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Text from 'components/ui/Text';

import { unique } from 'utils';

import Tab from './Tab';

import { convertQueryToPath } from '../utils';

import styles from './Filters.module.scss';

const Filters = ({
    recipes,
    recipesConfig,
    setActiveFilters,
    activeFilters,
    onFilterClick,
    className,
    filterSlug,
    isSearch,
}) => {
    const filterTypes = Object.keys(recipesConfig);

    const availableFilters = Object.fromEntries(
        filterTypes
            ?.map(type => {
                return [
                    type,
                    unique(
                        recipes.flatMap(recipe => {
                            return recipe.attributes?.[type];
                        })
                    ),
                ];
            })
            .filter(x => x)
    );

    const filterOptions = useMemo(() => {
        const options = Object.values(recipesConfig);

        const uniqueOptions = options.map(filter => {
            return filter;
        });

        return uniqueOptions;
    }, [recipesConfig]);

    useEffect(() => {
        const findMatches = filterOptions.map(filterOption => {
            const matchesWithCategory = Object.values(filterOption.options).map(
                filter => {
                    const option = {
                        category: filterOption.slug,
                        ...filter,
                    };
                    return option;
                }
            );

            const matches = matchesWithCategory.filter(filter => {
                if (!filterSlug) return;

                const match = Object.entries(filterSlug).find(item => {
                    const queryCategory = item[0];
                    const querySlug = item[1];

                    const findMatch = querySlug
                        ?.split(',')
                        ?.map(s => s === filter.slug);

                    return (
                        filter.category === queryCategory &&
                        (typeof findMatch === 'boolean'
                            ? findMatch
                            : findMatch?.includes(true))
                    );
                });
                return match;
            });

            return matches;
        });

        setActiveFilters(findMatches.flat());
    }, [filterOptions, filterSlug, setActiveFilters]);

    useEffect(() => {
        const updateQuery = query => {
            history.pushState(
                history.state,
                '',
                Object.keys(query).length
                    ? `/recipes/filters/${convertQueryToPath(query)}`
                    : '/recipes'
            );
        };

        const filterCategories = Object.keys(recipesConfig);

        const activeFilterQuery = activeFilters.map(item => {
            return {
                [item?.category]: item.slug,
            };
        });

        const filtersByType = filterCategories
            .map(key => {
                const match = activeFilterQuery
                    .map(item => {
                        if (Object.keys(item)[0] === key) {
                            return Object.values(item)[0];
                        }
                    })
                    .filter(x => x);

                return { [key]: match };
            })
            .filter(x => Object.values(x)[0].length);

        const filtersFormatted = Object.assign({}, ...filtersByType);

        if (activeFilters.length !== 0 && !isSearch) {
            updateQuery(filtersFormatted);
        }
    }, [activeFilters, recipesConfig, filterSlug, isSearch]);

    return (
        <div className={cx(styles.root, className)}>
            <div className={styles.inner}>
                {filterOptions?.map((filter, index) => {
                    const { slug, options, label } = filter;

                    const categorySize = Object.values(options)?.length;

                    const sortedOptions = options.sort((a, b) => {
                        if (a.label < b.label) {
                            return -1;
                        }
                        if (a.label > b.label) {
                            return 1;
                        }
                        return 0;
                    });
                    return (
                        <div
                            key={`category-${filter.slug}`}
                            style={{
                                '--column-size': Math.ceil(categorySize / 7),
                            }}
                            className={styles.category}
                        >
                            <Text
                                baseTheme="headingXSmall"
                                themes={{ large: 'bodyMedium' }}
                                className={styles.title}
                            >
                                {label}
                            </Text>
                            <div className={styles.items}>
                                {sortedOptions.map(filter => {
                                    const option = {
                                        category: slug,
                                        ...filter,
                                    };

                                    const isAvailable = Object.entries(
                                        availableFilters
                                    ).map(categories =>
                                        categories.some(items => {
                                            return items.includes(
                                                filter.id.toString()
                                            );
                                        })
                                    );

                                    return (
                                        <Tab
                                            isToggle
                                            key={`tab-${filter.id}`}
                                            label={filter.label}
                                            isActive={activeFilters.some(
                                                item =>
                                                    item.id === filter.id &&
                                                    item.category === slug
                                            )}
                                            isDisabled={!isAvailable[index]}
                                            onClick={() =>
                                                onFilterClick(option)
                                            }
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

Filters.propTypes = {
    activeFilters: PropTypes.array,
    className: PropTypes.string,
    filters: PropTypes.object,
    onFilterClick: PropTypes.func,
    filterSlug: PropTypes.object,
    recipesConfig: PropTypes.any,
    recipes: PropTypes.array,
    setActiveFilters: PropTypes.func,
    setLimit: PropTypes.func,
    isSearch: PropTypes.bool,
};

export default Filters;
