import React from 'react';

import PropTypes from 'prop-types';

import CarouselProvider from 'components/ui/CarouselProvider';

import Deck from './Deck';

const FlipDeck = ({
    slides,
    timerDuration = 7,
    isContinuous = false,
    flushTop = false,
    flushBottom = false,
    isLight = false,
    isCreamHeavy = false,
    auto = false,
}) => {
    return (
        <CarouselProvider
            slides={slides}
            timerDuration={timerDuration}
            isContinuous={isContinuous}
            auto={auto}
        >
            <Deck
                flushTop={flushTop}
                flushBottom={flushBottom}
                isLight={isLight}
                isCreamHeavy={isCreamHeavy}
            />
        </CarouselProvider>
    );
};

FlipDeck.propTypes = {
    auto: PropTypes.bool,
    flushBottom: PropTypes.bool,
    flushTop: PropTypes.bool,
    isContinuous: PropTypes.bool,
    isLight: PropTypes.bool,
    isCreamHeavy: PropTypes.bool,
    slides: PropTypes.array,
    timerDuration: PropTypes.number,
};

export default FlipDeck;
