import React, { useRef, useState } from 'react';

import { useRouter } from 'next-nprogress-bar';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import ButtonCircle from 'components/ui/ButtonCircle';
import Text from 'components/ui/Text';

import styles from './SearchBar.module.scss';

const SearchBar = ({ searchPlaceholder = 'What are you looking for?' }) => {
    const router = useRouter();

    const [searchTerm, setSearchTerm] = useState('');

    const handleSubmit = e => {
        e.preventDefault();

        if (searchTerm.length === 0) return;

        router.push(`/recipes/search/${searchTerm}`);
    };

    const inputRef = useRef(null);

    const renderSubmitContainer = () => {
        return (
            <div className={styles.submitContainer}>
                <Button
                    type="submit"
                    theme="none"
                    className={styles.textButton}
                >
                    <Text baseTheme="headingSmall2">Search</Text>
                </Button>

                <ButtonCircle
                    className={styles.circleButton}
                    theme="small-fill-blue"
                    iconType="arrowRightRounded"
                    type="submit"
                />
            </div>
        );
    };

    return (
        <form className={styles.root} onSubmit={handleSubmit}>
            <Text
                as="label"
                baseTheme="bodySmall"
                themes={{ large: 'bodyMedium' }}
            >
                <input
                    ref={inputRef}
                    name="search"
                    placeholder={searchPlaceholder}
                    autoComplete="off"
                    type="text"
                    onChange={e => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    className={styles.input}
                    enterKeyHint="search"
                />
            </Text>
            {renderSubmitContainer()}
        </form>
    );
};

SearchBar.propTypes = {
    searchPlaceholder: PropTypes.string,
};

export default SearchBar;
