import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Img from 'components/ui/Img';
import Link from 'components/ui/Link';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './CardRecipeGrid.module.scss';

const CardRecipeGrid = ({
    image,
    images,
    category,
    cookTime,
    prepTime,
    slug,
    title,
    isDoubleWide,
    attributes,
    recipesConfig,
    mealTypeLabels,
}) => {
    category ??= { title: '' };
    cookTime ??= 0;
    prepTime ??= 0;

    const attributesSorted = attributes?.meal?.sort((a, b) => a - b);
    const mealAttributes = recipesConfig?.meal.options;

    const mealTypes = attributesSorted
        ?.map(attr => {
            const match = mealAttributes?.filter(filter => {
                return filter.id === parseInt(attr, 10);
            });
            return match?.[0]?.label || false;
        })
        .filter(item => item !== false);

    const asset = images.length > 0 ? images[0] : image;

    return (
        <article
            className={cx(styles.root, { [styles.doubleWide]: isDoubleWide })}
        >
            <Link href={`/recipes/${slug}`} className={styles.inner}>
                <figure className={styles.aspectBox}>
                    {asset?.url && (
                        <Img
                            className={styles.image}
                            src={asset.url}
                            alt={asset.description || ''}
                            width={asset.width}
                            height={asset.height}
                            customSources={[
                                {
                                    breakpoint: 1440,
                                    src: asset.url,
                                    imageWidth: 1440 * 0.5,
                                },
                                {
                                    breakpoint: 1024,
                                    src: asset.url,
                                    imageWidth: 1024 * 0.5,
                                },
                                {
                                    breakpoint: 768,
                                    src: asset.url,
                                    imageWidth: 768 * 0.75,
                                },
                                {
                                    src: asset.url,
                                    imageWidth: 768,
                                },
                            ]}
                        />
                    )}
                </figure>
                <div className={styles.infoWrapper}>
                    <Text className={styles.category} baseTheme="labelMedium">
                        {mealTypes?.[0] ||
                            mealTypeLabels?.[0] ||
                            category?.title}
                    </Text>

                    <div className={styles.headlineWrapper}>
                        <Text
                            className={styles.headline}
                            baseTheme="headingSmall"
                            themes={{ xxLarge: 'headingMedium' }}
                            as="h5"
                        >
                            {title}
                        </Text>
                        <div className={styles.infoLockup}>
                            <SvgIcon className={styles.timeIcon} type="clock" />
                            <Text
                                className={styles.timeLabel}
                                baseTheme="labelMedium"
                                themes={{ large: 'labelLarge' }}
                            >
                                {prepTime + cookTime}MIN
                            </Text>
                        </div>
                    </div>
                </div>
            </Link>
        </article>
    );
};

CardRecipeGrid.propTypes = {
    attributes: PropTypes.shape({
        meal: PropTypes.array,
    }),
    category: PropTypes.shape({
        title: PropTypes.string,
    }),
    cookTime: PropTypes.number,
    image: PropTypes.shape({
        description: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        url: PropTypes.string,
    }),
    images: PropTypes.array,
    isDoubleWide: PropTypes.bool,
    prepTime: PropTypes.number,
    recipesConfig: PropTypes.object,
    mealTypeLabels: PropTypes.array,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string,
};

export default CardRecipeGrid;
