import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import cx from 'classnames';
import PropTypes from 'prop-types';

import CardNoResults from 'components/cards/CardNoResults';
import CardRecipeGrid from 'components/cards/CardRecipeGrid';
import ButtonCTA from 'components/ui/ButtonCTA';

import styles from './RecipesList.module.scss';

const RecipesList = ({
    recipes,
    activeFilters,
    recipesConfig,
    setLimit,
    limit,
}) => {
    const isNoResults = recipes.length === 0;

    const limitIncrement = activeFilters.length > 0 ? 6 : 10;

    const visibleItems =
        recipes.length <= limit ? recipes : recipes.slice(0, limit);

    const handleLoadMore = () => {
        setLimit(limit + limitIncrement);
    };

    return (
        <SwitchTransition>
            <CSSTransition
                key={activeFilters}
                timeout={{
                    enter: 300,
                    exit: 300,
                }}
                classNames={{
                    enter: styles['root-enter'],
                    enterActive: styles['root-enter-active'],
                    enterDone: styles['root-enter-done'],
                    exit: styles['root-exit'],
                    exitActive: styles['root-exit-active'],
                    exitDone: styles['root-exit-done'],
                }}
            >
                <section className={styles.root}>
                    <ul
                        className={cx(styles.inner, {
                            [styles.truncated]: recipes.length > limit,
                        })}
                    >
                        {visibleItems.map((recipe, i) => {
                            if (!recipe.slug) {
                                // These are an absolute must
                                return null;
                            }
                            const idx = i + 1;
                            const isDoubleWide =
                                activeFilters?.length === 0 &&
                                (idx % 10 === 0 || idx % 10 === 4);

                            return (
                                <li
                                    className={cx(styles.listItem, {
                                        [styles.doubleWide]: isDoubleWide,
                                    })}
                                    key={i}
                                >
                                    <CardRecipeGrid
                                        {...recipe}
                                        images={recipe.imagesCollection.items}
                                        recipesConfig={recipesConfig}
                                        isDoubleWide={isDoubleWide}
                                    />
                                </li>
                            );
                        })}
                        {isNoResults && (
                            <li
                                className={cx(
                                    styles.listItem,
                                    styles.noResults
                                )}
                            >
                                <CardNoResults />
                            </li>
                        )}
                    </ul>
                    {recipes.length > limit && (
                        <div className={styles.loadButton}>
                            <span className={styles.buttonBg}>
                                <ButtonCTA
                                    text="Load More"
                                    style="filled-blue-ocean"
                                    onClick={handleLoadMore}
                                />
                            </span>
                        </div>
                    )}
                </section>
            </CSSTransition>
        </SwitchTransition>
    );
};

RecipesList.propTypes = {
    activeFilters: PropTypes.array,
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    limit: PropTypes.number,
    recipesConfig: PropTypes.object.isRequired,
    recipes: PropTypes.array,
    setLimit: PropTypes.func,
    useCategorySort: PropTypes.bool,
};

export default RecipesList;
