import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import styles from './Tab.module.scss';

const Tab = ({ onClick, isActive, label, isDisabled, isToggle, className }) => {
    isDisabled ??= false;
    isToggle ??= false;

    const showClosedIcon = !isToggle;

    return (
        <button
            className={cx(styles.root, className, {
                [styles.isActive]: isActive,
                [styles.isToggle]: isToggle,
                [styles.isDisabled]: isDisabled,
            })}
            gtm-label={`filter - ${label}`}
            onClick={onClick}
        >
            {isActive && (
                <span className={styles.checkIcon}>
                    <SvgIcon type="checkmark" />
                </span>
            )}
            <Text
                className={styles.label}
                baseTheme={isToggle ? 'labelMedium' : 'labelSmall'}
            >
                {label}
            </Text>
            {showClosedIcon && (
                <span className={styles.closeIcon}>
                    <SvgIcon type="close" />
                </span>
            )}
        </button>
    );
};

Tab.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isToggle: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
};

export default Tab;
