//query utils from: https://medium.com/@igorbadanuk/next-js-advanced-ssg-with-path-query-2b984e713c3f

const valueDelimiter = ',';
const keyValueDelimiter = '=';
const queryPartsDelimiter = '&';

export function convertQueryToPath(query) {
    const stringsQuery = [];

    for (const [key, value] of Object.entries(query).sort((a, b) => {
        if (a[0][0] < b[0][0]) return -1;
        if (a[0][0] > b[0][0]) return 1;
        return 0;
    })) {
        if (typeof value === 'string') {
            stringsQuery.push(`${key}${keyValueDelimiter}${value}`);
        }

        if (Array.isArray(value)) {
            stringsQuery.push(
                `${key}${keyValueDelimiter}${value
                    .sort((a, b) => {
                        if (a < b) return -1;
                        if (a > b) return 1;
                        return 0;
                    })
                    .join(valueDelimiter)}`
            );
        }
    }

    return stringsQuery.join(queryPartsDelimiter);
}
