import React from 'react';

import PropTypes from 'prop-types';

import TouchSwipe from 'utils/TouchSwipe';

const Swiper = ({
    className,
    next,
    prev,
    tap,
    mouse,
    children,
    ...otherProps
}) => {
    return (
        <TouchSwipe
            className={className}
            onTap={tap}
            onSwipedLeft={next}
            onSwipedRight={prev}
            mouse={mouse}
            {...otherProps}
        >
            {children}
        </TouchSwipe>
    );
};

Swiper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    next: PropTypes.func,
    prev: PropTypes.func,
    tap: PropTypes.func,
    mouse: PropTypes.bool,
};

export default Swiper;
