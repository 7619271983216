import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import cx from 'classnames';

import FlipDeck from 'components/ui/FlipDeck';
import Link from 'components/ui/Link';
import ReCaptchaLegal from 'components/ui/ReCaptchaLegal';
import Spinner from 'components/ui/Spinner';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import useSubscribe from 'hooks/useSubscribe';
import { EMAIL_REGEX } from 'utils';
import { event } from 'utils/gtm';

import styles from './Newsletter.module.scss';

const slides = [
    {
        __typename: '',
        image: {
            title: 'egg-cheese-breakfast-toast',
            url: 'https://images.ctfassets.net/j8tkpy1gjhi5/37V2zJYDw0sm6kF7YYbdnj/5a5467a01ba26520b739eb49e248b7e4/egg-cheese-breakfast-toast.png',
            width: 816,
            height: 816,
        },
        caption: 'Crispy Cheese and Egg Breakfast Toast',
    },
    {
        __typename: '',
        image: {
            title: 'salami-red-onion-pizza',
            url: 'https://images.ctfassets.net/j8tkpy1gjhi5/5GP0VbShX8VlFpcHDzJfQD/9fdd7dbbffb3de4f823dd8ed0fec2d7c/salami-red-onion-pizza.png',

            width: 816,
            height: 816,
        },
        caption: 'Salami, Red Onion and Oregano Pizza',
    },
    {
        __typename: '',
        image: {
            title: 'caprese-grilled-cheese-sandwich',
            url: 'https://images.ctfassets.net/j8tkpy1gjhi5/sVvjjSqrHIzPrsAZFYYvr/a84d580efe941c88c928e08a63f9bae0/caprese-grilled-cheese-sandwich.png',
            width: 816,
            height: 816,
        },
        caption: 'Caprese Grilled Cheese Sandwich',
    },
];

const Newsletter = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { subscribe, isSubmitting, isSuccess, isError, isSubscribed } =
        useSubscribe('Recipes_Index_General_Messaging', true);

    useEffect(() => {
        if (isSuccess) {
            event('new_subscriber', {
                formLocation: 'block',
                source: 'Recipes_Index_General_Messaging',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <div className={styles.root}>
            <div className={styles.inner}>
                <div className={styles.contentWrapper}>
                    <h1 className={styles.headline}>want more recipes?</h1>
                    <div className={styles.copyWrapper}>
                        <Text
                            as="p"
                            className={styles.description}
                            baseTheme="bodyMedium"
                            themes={{ large: 'bodyLarge' }}
                            fixWidows
                        >
                            We’re always cooking up new things. Sign up for
                            recipe emails and we’ll keep it fresh.
                        </Text>
                    </div>
                    <form
                        className={styles.form}
                        onSubmit={handleSubmit(data => {
                            delete data.terms;
                            subscribe(data);
                        })}
                    >
                        <div
                            className={cx(styles.inputLockup, {
                                [styles.inputSuccess]: isSuccess,
                            })}
                        >
                            <Text
                                className={styles.inputWrap}
                                baseTheme="bodyMedium"
                            >
                                {isSuccess ? (
                                    <p className={styles.input}>
                                        Successfully submitted!
                                    </p>
                                ) : (
                                    <input
                                        {...register('email_address', {
                                            required: true,
                                            pattern: EMAIL_REGEX,
                                        })}
                                        className={cx(styles.input, {
                                            [styles.inputError]:
                                                errors.email_address ||
                                                isSubscribed,
                                        })}
                                        type="email"
                                        placeholder={'Enter your email'}
                                    />
                                )}
                            </Text>
                            <div
                                className={cx(styles.submitButtonWrap, {
                                    [styles['submitButtonWrap__isLoading']]:
                                        isSubmitting,
                                    [styles['submitButtonWrap__isSuccess']]:
                                        isSuccess,
                                })}
                            >
                                <button
                                    type="submit"
                                    className={styles.submitButton}
                                >
                                    <span className="sr-only">Submit</span>
                                    <SvgIcon
                                        type="arrowRight"
                                        className={styles.submitArrow}
                                    />
                                </button>
                                <div className={styles.buttonSpinner}>
                                    <Spinner />
                                </div>
                            </div>
                        </div>
                        <div className={styles.reCaptchaLegal}>
                            <ReCaptchaLegal />
                        </div>
                        <Text baseTheme="bodySmall" className={styles.checkbox}>
                            <label
                                className={cx(styles.checkbox, {
                                    [styles['checkbox-error']]: errors.terms,
                                })}
                            >
                                I agree to receive emails for marketing
                                purposes. Read{' '}
                                <Link
                                    href="/privacy-policy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy
                                </Link>
                                <input
                                    type="checkbox"
                                    {...register('terms', {
                                        required: true,
                                    })}
                                />
                                <span className={styles.checkmark} />
                            </label>
                        </Text>
                        <div className={styles.joinError}>
                            <Text
                                className={styles.joinErrorInner}
                                baseTheme="bodyXSmall"
                            >
                                {(errors.email_address || isError) &&
                                    'Email is invalid'}
                                {isSubscribed && (
                                    <>
                                        Oops, looks like you&rsquo;re already
                                        subscribed.
                                    </>
                                )}
                            </Text>
                        </div>
                    </form>
                </div>
                <div className={styles.deckWrapper}>
                    <FlipDeck slides={slides} isCreamHeavy />
                </div>
            </div>
        </div>
    );
};

export default Newsletter;
