'use client';

import React, { useCallback } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/ui/Button';
import SvgIcon from 'components/ui/SvgIcon';
import Text from 'components/ui/Text';

import SearchBar from './SearchBar';

import styles from './Header.module.scss';

const Header = ({ className, showFilters, setShowFilters }) => {
    const handleClick = useCallback(() => {
        setShowFilters(!showFilters);
    }, [setShowFilters, showFilters]);

    return (
        <>
            <div className={cx(styles.root, className)}>
                <Text
                    className={styles.title}
                    baseTheme="displayXSmall"
                    themes={{ large: 'displaySmall' }}
                >
                    Recipes
                </Text>
                <div className={styles.searchContainer}>
                    <SearchBar />
                    <Button
                        theme="none"
                        className={cx(styles.filterButton, {
                            [styles.isOpen]: showFilters,
                        })}
                        onClick={handleClick}
                    >
                        <SvgIcon type="restaurantFilters" />
                        <span className="sr-only">Open Filters Panel</span>
                    </Button>
                </div>
            </div>
        </>
    );
};

Header.propTypes = {
    className: PropTypes.string,
    setShowFilters: PropTypes.func,
    showFilters: PropTypes.bool,
};

export default Header;
